.container {
  background-color: var(--color-grey);
  text-align: center;
  min-height: calc(100vh - var(--height-footer) - var(--height-header));
  display: flex;
  background-image: none;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.h1 {
  color: var(--color-primary);
  font-size: 3rem;
  letter-spacing: 5px;
  text-shadow: 1px 1px var(--color-secondary);
  margin: 15px 0;
}

.empty {
  display: none;
  height: 100%;
  width: 50%;
}

.contact {
  background-color: var(--color-grey);
  padding: 0 20px;
  max-width: 500px;
  margin: auto;
  border-radius: 30px;
}

.form {
  align-items: center;
  background-color: var(--color-light-grey);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid var(--color-secondary);
  border-radius: 20px;
}

.containerTextarea {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-top: 20px;
}

.textarea {
  background-color: var(--color-white);
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: var(--color-primary);
  font-size: 16px;
  height: 100%;
  outline: 0;
  padding: 10px 20px 0;
  width: 100%;
  resize: vertical;
  min-height: 150px;
  font-family: Arial, Helvetica, sans-serif;
}

.cut {
  background-color: var(--color-light-grey);
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
}

.error {
  font-family: sans-serif;
  font-size: 13px;
  color: red;
  background-color: MistyRose;
  border: 1px solid red;
  border-radius: 10px;
  padding: 3px 6px;
  margin-left: auto;
}

.messageSuccess {
  color: var(--color-secondary);
  border-radius: 10px;
  font-size: 23px;
  font-weight: 700;
  padding: 5px;
  max-width: 250px;
}

.messageError {
  color: red;
  background-color: MistyRose;
  border-radius: 10px;
  padding: 5px;
  max-width: 250px;
}

.textarea:focus ~ .cut,
.textarea:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.placeholder {
  color: var(--color-muted);
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.textarea:focus ~ .placeholder,
.textarea:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.textarea:not(:placeholder-shown) ~ .placeholder {
  color: var(--color-muted);
}

.textarea:focus ~ .placeholder {
  color: var(--color-secondary);
}

@media screen and (max-width: 410px) {
  .h1 {
    font-size: 2.3rem;
    letter-spacing: 1.7px;
  }
}

@media screen and (min-width: 576px) {
  .container {
    background-image: url(../assets/mobile.jpg);
  }
}

@media screen and (min-width: 961px) {
  .empty {
    display: inherit;
  }
}
