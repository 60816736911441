.container {
  position: relative;
  background-color: var(--color-grey);
  width: 100%;
  display: flex;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - var(--height-footer) - var(--height-header));
}

.routeMainInfo {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.routeSecondaryInfo {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 50%;
  top: -100%;
  left: 0;
  right: 0;
  opacity: 1;
  z-index: 1;
}

.routeSecondaryInfoActive {
  background-color: var(--color-grey);
  transition: all 0.5s ease-in-out;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
}

@media screen and (min-width: 769px) {
  .routeMainInfo {
    flex-direction: row;
    height: calc(100% - var(--height-bottom));
  }
  .routeSecondaryInfo {
    position: initial;
    flex-direction: row;
    height: var(--height-bottom);
  }
}
