.iconCard {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.icon {
  font-size: 25px;
}

@media screen and (min-width: 961px) {
  .icon {
    font-size: 40px;
  }
}
