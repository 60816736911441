.button {
    box-sizing: border-box;
    appearance: none;
    background-color: var(--color-primary);
    border: 2px solid var(--color-secondary);
    border-radius: 0.6em;
    color: var(--color-secondary);
    cursor: pointer;
    display: flex;
    align-self: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 20px auto;
    padding: 1em 2.8em;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    transition: box-shadow 0.5s ease-in-out, color 0.3s ease-in-out;
    transform: scale(1);
    filter: drop-shadow(5px 5px 8px var(--color-muted));
}

.button:hover {
    box-shadow: 0 0 40px 40px var(--color-secondary) inset;
    color: var(--color-white);
    outline: 0;
}

.button:active {
    transform: scale(0.9);
    filter: none;
}

