.container {
  display: none;
  background-color: var(--color-primary);
  min-width: var(--width-sidebar);
}

.h4 {
  text-align: center;
  margin: 10px 0;
  letter-spacing: 4px;
  color: var(--color-white);
}

@media screen and (min-width: 1200px) {
  .container {
    display: initial;
  }
}
