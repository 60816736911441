.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.inputForm {
  position: relative;
  height: 50px;
}

.input {
  background-color: var(--color-white);
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: var(--color-primary);
  font-size: 16px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}

.cut {
  background-color: var(--color-light-grey);
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  padding-right: 35px;
}

.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.error {
  font-family: sans-serif;
  font-size: 13px;
  color: red;
  background-color: MistyRose;
  border: 1px solid red;
  border-radius: 10px;
  padding: 3px 6px;
  margin-left: auto;
}

.placeholder {
  color: var(--color-muted);
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: var(--color-muted);
}

.input:focus ~ .placeholder {
  color: var(--color-secondary);
}
