.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.h5 {
  padding: 3px 20px;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

/* NOTE: issue with size when put 100% */
.content {
  height: 100%;
  width: 100%;
  position: relative;
  width: calc(100% - 9px);
}

.chart {
  height: 100%;
  width: 100%;
  position: absolute;
  padding: 0 4px;
}
