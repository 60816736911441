.loader {
  position: relative;
  display: flex;
  min-height: 92px;
  padding-top: 20px;
}

.static {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.absolute {
  position: absolute;
  align-self: center;
}

.loader span {
  display: block;
  width: 20px;
  height: 20px;
  background: var(--color-white);
  border-radius: 50%;
  margin: 0 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.loader span:nth-child(2) {
  background: var(--color-secondary);
}

.loader span:nth-child(3) {
  background: var(--color-passive);
}

.loader span:nth-child(4) {
  background: var(--color-primary);
}

.loader span:not(:last-child) {
  animation: animate 1.5s linear infinite;
}

@keyframes animate {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(30px);
  }
}

.loader span:last-child {
  animation: jump 1.5s ease-in-out infinite;
}

@keyframes jump {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(10px, -10px);
  }
  20% {
    transform: translate(20px, 10px);
  }
  30% {
    transform: translate(30px, -50px);
  }
  70% {
    transform: translate(-150px, -50px);
  }
  80% {
    transform: translate(-140px, 10px);
  }
  90% {
    transform: translate(-130px, -10px);
  }
  100% {
    transform: translate(-120px, 0);
  }
}
