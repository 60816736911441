.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.h5 {
  padding: 3px 20px;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.contentRow {
  height: 100%;
  display: flex;
  justify-content: space-around;
  gap: 1px;
  margin: 0;
  padding: 4px;
  list-style-type: none;
}

.contentItem {
  bottom: 0;
  width: 100%;
  max-width: 60px;
  min-width: 30px;
  display: flex;
  border-radius: 10px;
  background-color: var(--color-white-trans);
  padding: 4px;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.day {
  font-size: 12px;
  text-transform: capitalize;
  white-space: nowrap;
}

.weatherCol {
  display: flex;
  flex-direction: column;
}

.degrees {
  color: var(--color-primary);
  white-space: nowrap;
}

.wind {
  font-size: 12px;
  white-space: nowrap;
  padding-bottom: 2px;
}

.weatheIcon {
  height: 35px;
  padding: 2px;
}

.weatherLogo {
  height: 50px;
}

/* NOTE: maybe padding needed on noData option */
.link {
  display: flex;
  text-decoration: none;
  color: var(--color-black);
}