.container {
  height: 100%;
  width: 100%;
  display: flex;
}

.map {
  position: relative;
  height: 100%;
  width: 100%;
}

.mapBtnContainerLeftTop {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  float: left;
}

.mapBtnContainerRightBottom {
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 44px;
  float: left;
}

.mapButton {
  border: none;
  padding: 0;
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  display: block;
  height: 29px;
  overflow: hidden;
  width: 29px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  margin: 10px;
  background: #fff;
  border-radius: 4px;
}

.popup {
  width: 100%;
}

.popupHeader {
  display: flex;
  width: 100%;
  height: 100%;
}
.popupHeaderIcon {
  padding: 0 5px;
  align-self: center;
}

.h6 {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 17px;
  margin: 5px 0;
}

.popupUrl {
  display: none;
  outline: none;
  padding: 0 5px;
  font-size: 20px;
  align-items: center;
  border: solid 1px #fff0;
  border-radius: 4px;
}
.popupUrl:hover {
  border: solid 1px var(--color-secondary);
}

.popupButton {
  outline: none;
  padding: 0 5px;
  font-size: 20px;
  border: solid 1px #fff0;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
}
.popupButton:hover {
  border: solid 1px var(--color-secondary);
}

.popupContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: solid 3px var(--color-light-grey);
  padding: 5px 2px;
  margin: 0;
  gap: 5px;
}

.popupContent_item {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0;
  margin: 0;
}
.popupContent li:not(:last-child) {
  border-bottom: solid 2px var(--color-light-grey);
}

.popupContent :global(.p-button) {
  background-color: initial;
  border-color: var(--color-secondary);
  padding: 8px 0;
  color: var(--color-secondary);
}

.popupContent_item_row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 5px;
}

.popupLink {
  outline: none;
  text-align: end;
}

.popupContent_notification_container {
display: flex;
flex-direction: row;
}

.popupContent_notification {
  padding: 5px 10px;
  font-size: 15px;
  font-weight: 700;
}

.buyButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
}
.buyButton :global(.p-button-outlined) {
  width: 100%;
  border-color: #d1d5db;
  padding: 6px 12px;
}
.buyButton :global(.p-button-outlined:hover) {
  border-color: var(--color-secondary);
}
.buyButton :global(.p-button-label) {
  font-size: 16px;
  font-weight: 400;
  color: var(--color-black);
  text-align: start;
}

.dialog {
  width: 100%;
  height: 100%;
  max-width: 1400px;
}
.dialog :global(.p-dialog-header) {
  background-color: var(--color-light-grey);
}
.dialog :global(.p-dialog-content) {
  padding: 0;
}

.slider {
  max-width: 220px;
  margin: 10px auto;
}

@media screen and (min-width: 400px) {
  .slider {
    max-width: 300px;
  }
}

@media screen and (min-width: 961px) {
  .mapButton:hover {
    background: #f0f0f0;
  }
  .popupUrl {
    display: flex;
  }
  .popupButton {
    display: none;
  }
}
