:root {
  --color-primary: #14453b;
  --color-secondary: #0ed145;
  --color-passive: #21857b;
  --color-white: #fff;
  --color-white-trans: #ffffffde;
  --color-dark-trans: #14453bbe;
  --color-light-grey: #e1ecee;
  --color-grey: #b7cece;
  --color-muted: #92a1a6;
  --color-black: #0c1919;
  --color-train: red;
  --color-port: blue;
  --color-bus: green;
  --color-parking: orange;
  --color-shadow: #bed3d1;
  --color-navigation: #1da1f2;
  --color-difficulty-1: #ffb602;
  --color-difficulty-2: #ff3502;
  --font-primary: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --font-header: "Roboto", sans-serif;
  --height-header: 80px;
  --height-footer: 30px;
  --height-bottom: 140px;
  --width-sidebar: 170px;
}

@media screen and (max-width: 960px) {
  :root {
    --height-header: 60px;
  }
}
