.h4 {
  margin: auto 0;
}

.routeCardContainer {
  background-color: var(--color-light-grey);
  margin: 0 5px 7px;
  border-radius: 20px;
  border: solid 2px var(--color-dark-trans);
  min-height: 60px;
  cursor: pointer;
  list-style-type: none;
}

.routeCard {
  display: block;
  color: inherit;
  text-decoration: inherit;
  padding: 5px 10px;
}

.routeCardContainer:hover {
  background-color: var(--color-white);
  box-shadow: 1px 1px 2px var(--color-dark-trans);
}

.routeCardLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.betweenIcons {
  display: flex;
}

.distance {
  color: var(--color-secondary);
  margin: auto 5px;
  font-size: 25px;
  font-weight: 700;
}

.iconCard {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.icon {
  font-size: 30px;
}

@media screen and (min-width: 769px) {
  .betweenIcons {
    gap: 1vw;
  }
}