.container {
    background-color: var(--color-white);
    position: relative;
    height: 100%;
    width: 100%;
}

/*hero*/
.hero {
    background-image: url(../assets/forest-mobile.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: calc(100vh - var(--height-header));
    clip-path: circle(80.5% at 26% 7%);
}

.home {
    position: absolute;
    right: 2vw;
    top: 60vh;
    text-align: right;
}

.h1 {
    position: absolute;
    top: 10vh;
    left: 2vw;
    right: 2vw;
    padding: 15px;
    z-index: 1;
    color: var(--color-white);
    background-color: var(--color-dark-trans);
    text-shadow: 1px 1px 2px var(--color-secondary);
    border-radius: 6px;
    text-align: center;
    margin: 1rem 1rem 2rem;
    font-size: calc(2vw + 12px);
    letter-spacing: initial;
}

.h2 {
    text-align: right;
    padding: 0.5rem;
    color: var(--color-primary);
    text-shadow: 1px 1px 2px var(--color-passive);
    font-weight: 700;
    background-color: var(--color-white-trans);
    border-radius: 6px;
}

/* Summary */
.summary {
    background-color: var(--color-light-grey);
    padding: 60px 30px;
}

.h3 {
    margin: 30px;
    text-transform: uppercase;
    font-size: 1.2em;
    position: relative;
}

.h3:after {
    content: "";
    position: absolute;
    max-width: 320px;
    width: 85%;
    height: 100%;
    top: 2px;
    left: 0;
    border-bottom: 2px solid var(--color-secondary);
}

.summaryItems {
    display: grid;
    grid-template-columns: 1fr;
    padding: 20px 0;
    max-width: 992px;
    margin: 0 auto;
    gap: 20px;
}

.summaryItem {
    text-align: center;
    padding: 20px;
}

.h4 {
    font-size: 1.3rem;
    margin: 20px 0;
    letter-spacing: 5px;
}

/* Home bottom */
.bottom {
    height: 100%;
    display: none;
}

.bottomPicture {
    height: 40vh;
    width: 100%;
    background-image: url(../assets/sign2thin.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.h5 {
    font-size: calc(5vw + 12px);
    padding: 0 20px;
    margin: auto auto;
}

/* @media */
@media screen and (min-width: 576px) {
    .hero {
        background-image: url(../assets/forest.jpg);
    }
    .summary {
        background-color: initial;
    }
    .summaryItems {
        grid-template-columns: 1fr 1fr;
    }
    .bottom {
        display: flex;
    }
}

@media screen and (min-width: 992px) {
    .summaryItems {
        grid-template-columns: 1fr 1fr 1fr;
    }
}