@media screen and (min-width: 961px) {
  html {
    /* NOTE: both needed for jumpy navbar*/
    overflow-x: hidden;
    margin-right: calc(-1 * (100vw - 100%));
  }
}

.mapboxgl-popup-content {
  padding: 5px;
  border-radius: 10px;
}
.maplibregl-popup-content {
  padding: 5px !important;
  border-radius: 10px !important;
}

/* NOTE: edited component styles for PRIMEREACT */
.p-button {
  background-color: var(--color-primary);
  border-color: var(--color-secondary);
  color: var(--color-secondary);
}
.p-button-raised:hover {
  box-shadow: 0 0 3px 2px var(--color-secondary);
}
.p-button:focus {
  box-shadow: none;
}
.p-button:active {
  background-color: var(--color-passive);
}
.p-component:hover {
  border-color: var(--color-secondary);
}
.p-highlight {
  background-color: var(--color-light-grey);
  color: var(--color-passive);
}

.p-link:hover {
  color: var(--color-secondary);
}

.p-checkbox .p-checkbox-box {
  border-color: var(--color-grey);
}

.p-highlight .p-checkbox-box {
  border-color: var(--color-secondary);
  background: var(--color-secondary);
}

.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
  border-color: var(--color-secondary);
  box-shadow: 0 0 0 0.2em var(--color-light-grey);
}

.p-calendar.p-inputtext:focus-within {
  box-shadow: 0 0 0 0.2em var(--color-light-grey);
}

.p-inputtext:hover {
  border-color: var(--color-secondary);
}
.p-inputtext {
  color: var(--color-black);
  padding: 8px 12px;
}
.p-inputtext:focus-within {
  border-color: var(--color-secondary);
}

.p-autocomplete-input:focus-within {
  box-shadow: 0 0 0 0.2em var(--color-light-grey);
}

.p-dropdown:hover {
  border-color: var(--color-secondary);
}
.p-dropdown:focus-within {
  border-color: var(--color-secondary);
  box-shadow: 0 0 0 0.2em var(--color-light-grey);
}
.p-dropdown-filter:focus-within {
  box-shadow: 0 0 0 0.2em var(--color-light-grey);
}
.p-dropdown-trigger {
  color: var(--color-secondary);
}

.p-dialog-header-close:focus-within {
  box-shadow: 0 0 0 0.2em var(--color-grey);
}
.p-dialog-content {
  padding-top: 0.5rem;
}

.p-slider {
  background-color: var(--color-light-grey);
}
.p-slider-range {
  background-color: var(--color-secondary);
}
.p-slider-handle {
  border-color: var(--color-secondary);
  height: 1.5rem;
  width: 1.5rem;
  margin-top: -0.75rem;
  margin-left: -0.75rem;
}
.p-slider-handle:hover {
  background-color: var(--color-passive);
}

/* Tooltip template */
/* .p-tooltip-text {
  color: black;
  white-space: nowrap;
  font-size: 10px;
  padding: 6px 8px;
  background-color: white;
  border-radius: 0;
  border: solid 1px black;
  font-size: 12px;
  font-family: sans-serif;
} */
/* import { Tooltip } from 'primereact/tooltip'; */
/* <Tooltip target=".tooltip"/> */
/* <FaTrain
  data-pr-tooltip={t("routes_card_train")}
  data-pr-position="bottom"
  className={`${st.icon} tooltip`}
/> */
