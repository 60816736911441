.container {
  background-color: var(--color-grey);
  text-align: center;
  min-height: calc(100vh - var(--height-footer) - var(--height-header));
  background-image: none;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 20px;
}

.h1 {
  margin: 0;
  padding: 30px 0 10px;
  color: var(--color-primary);
  font-size: 3rem;
  letter-spacing: 5px;
  text-shadow: 1px 1px var(--color-secondary);
}

.about {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.card {
  margin: 15px;
  color: var(--color-white);
  padding: 20px;
  background-color: var(--color-dark-trans);
  border-radius: 20px;
  max-width: 100%;
  border: solid 2px var(--color-secondary);
  transition: all 0.6s;
  transform: scale(1);
  filter: none;
}

.h4 {
  font-size: 30px;
  margin: 0;
  letter-spacing: 5px;
}

.card:hover {
  transform: scale(1.1);
  text-shadow: 2px 2px var(--color-black);
  background-color: var(--color-dark-trans);
  box-shadow: 0 1px 5px var(--color-secondary) inset;
  filter: drop-shadow(5px 5px 8px var(--color-dark-trans));
}

@media screen and (min-width: 576px) {
  .container {
    background-image: url(../assets/field.jpg);
  }
  .card {
    background-color: initial;
  }
}

@media screen and (min-width: 769px) {
  .card {
    max-width: 39%;
  }
}