.legendButton {
  display: flex;
  outline: none;
  padding: 0 5px;
  margin: 0 5px;
  font-size: 27px;
  align-self: center;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
}
.dialog {
  width: 100%;
  max-width: 1400px;
}
.legend {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-left: 0;
}
.legend > li {
  display: flex;
  padding-bottom: 15px;
}
.distance {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-right: 15px;
}
.distance > span {
  color: var(--color-secondary);
  font-size: 27px;
  font-weight: 700;
}
.icon {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: var(--color-primary);
  padding-right: 15px;
  padding-bottom: 20px;
}

@media screen and (min-width: 769px) {
  .legend {
    margin: 0 10vw;
  }
}
