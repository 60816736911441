.footer {
    color: var(--color-muted);
    box-shadow: inset 0 -1px 4px var(--color-secondary);
    height: var(--height-footer);
    width: 100%;
    background-color: var(--color-primary);
}

.copyright {
    margin: 0;
    text-align: center;
    font-size: 15px;
}

@media screen and (min-width: 360px) {
    .copyright {
        font-size: initial;
    }
}