.container {
  background-color: var(--color-grey);
  text-align: center;
  min-height: calc(100vh - var(--height-footer) - var(--height-header));
  background-image: none;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 20px;
}

.h3 {
  margin: 0;
  padding: 30px;
  color: var(--color-primary);
  font-size: 25px;
  text-shadow: 1px 1px var(--color-secondary);
}

.sign {
  display: flex;
  margin: 50px auto;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 10px solid red;
  background-color: var(--color-white);
  align-self: center;
  justify-content: center;
  align-items: center;
}

.sign svg {
  width: 60%;
  height: auto;
}

@media screen and (min-width: 576px) {
  .container {
    background-image: url(../assets/sign.jpg);
  }
  .h3 {
    padding: 70px 50px 0 40vw;
    font-size: 35px;
  }
  .sign {
  display: none;
  }
}
