.container {
  background-color: var(--color-white-trans);
  margin: 2px 5px;
  padding: 7px 10px;
  border-radius: 20px;
  border: solid 2px var(--color-primary);
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.popupContent_PublicTransport_header {
  display: flex;
  margin-bottom: 5px;
  padding: 0 5px 0 20px;
  font-size: 15px;
  font-weight: 700;
  justify-content: space-around;
  align-items: center;
  gap: 2px;
  height: 20px;
}

.popupContent_PublicTransport {
  display: flex;
  flex-direction: column;
  padding: 0 30px 0 0;
  margin: 0;
  height: 100%;
  gap: 1px;
  overflow-y: auto;
}

.popupContent_PublicTransport_title {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-auto-flow: column;
  text-align: end;
  font-size: 10px;
  gap: 1px;
}

.popupContent_PublicTransport_row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-auto-flow: column;
  text-align: end;
}

.popupUrlButton {
  display: flex;
  outline: none;
  padding: 0 5px;
  font-size: 20px;
  align-items: center;
  border: solid 1px #fff0;
  border-radius: 4px;
}

.noPublicTransport {
  text-align: center;
}

.h5 {
  font-size: 20px;
  font-weight: 400;
  color: var(--color-primary);
  padding: 0 30px;
  margin: 0;
}

.publicTransport {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 50%;
  width: 100%;
  left: -100vw;
  bottom: 0;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  z-index: 1;
}

.publicTransportActive {
  background-color: var(--color-grey);
  transition: all 0.5s ease-in-out;
  left: 0;
  z-index: 10;
  opacity: 1;
}

.transportSign {
  display: none;
}

@media screen and (min-width: 769px) {
  .container {
    width: 100%;
  }
  .h5 {
    padding: 0 10px;
    align-self: center;
    min-width: 130px;
  }
  .publicTransport {
    flex-direction: row;
    height: var(--height-bottom);
  }
}

@media screen and (min-width: 1200px) {
  .publicTransport {
    width: calc(100% - var(--width-sidebar));
  }
  .transportSign {
    display: flex;
    margin: 20px;
    min-width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 3px solid var(--color-primary);
    background-color: var(--color-white);
    align-self: center;
    justify-content: center;
    align-items: center;
  }
}