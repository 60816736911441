.container {
  background-color: var(--color-grey);
  text-align: center;
  min-height: calc(100vh - var(--height-footer) - var(--height-header));
  padding: 0 20px;
}

.h1 {
  margin: 0;
  padding: 30px 0 10px;
  color: var(--color-primary);
  font-size: 3rem;
  letter-spacing: 5px;
  text-shadow: 1px 1px var(--color-secondary);
}

.contribute {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.content {
  margin-top: 0;
}

.legend {
  display: flex;
  padding-bottom: 30px;
}

@media screen and (min-width: 769px) {
  .contribute {
    margin: 0 10vw;
  }
}
