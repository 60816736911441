.container :global(.p-dropdown) {
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
}
.container :global(.p-dropdown:hover) {
  border-color: var(--color-secondary);
}
.container :global(.p-dropdown:focus-within) {
  box-shadow: none;
}

.dropdown :global(.p-inputtext) {
  padding: 4px;
  color: var(--color-white);
  line-height: 40px;
}
.dropdown :global(.p-dropdown-label) {
  background-color: var(--color-primary);
  width: 2rem;
}
.dropdown :global(.p-dropdown-trigger) {
  background-color: var(--color-primary);
  width: 1rem;
  padding-right: 4px;
}

@media screen and (max-width: 960px) {
  .container {
    align-self: center;
    position: fixed;
    bottom: 20px;
  }
}
