.header {
    position: sticky;
    top: 0;
    display: flex;
    width: calc(100vw - (100vw - 100%));
    height: var(--height-header);
    font-size: 1.2rem;
    background-color: var(--color-primary);
    box-shadow: 0 1px 5px var(--color-secondary);
    z-index: 50;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1300px;
    margin: auto;
}

.logo {
    color: var(--color-secondary);
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
}

.menu {
    display: flex;
    list-style: none;
    text-align: center;
    align-items: center;
    margin-right: 20px;
}

.links {
    position: relative;
    color: var(--color-white);
    text-decoration: none;
    padding: 0.5rem 1.5rem;
    align-items: center;
    z-index: 0;
}

.links:hover {
    background-color: var(--color-secondary);
}

.links:before {
    content: "";
    position: absolute;
    transition: 0.3s;
    width: 100%;
    height: 100%;
    top: -2px;
    left: 0;
    border-bottom: 2px solid var(--color-secondary);
    border-top: 2px solid var(--color-secondary);
    transform: scaleY(1.5);
    opacity: 0;
}

.links:after {
    content: "";
    position: absolute;
    transition: 0.5s;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--color-primary);
    transform: scale(1);
    z-index: -1;
}

.active {
    color: var(--color-secondary);
}

.links:active {
    color: var(--color-white);
}

.links:hover:before {
    transform: scaleY(1.1);
    opacity: 1;
}

.links:active:after {
    transform: scale(0);
    transition: 0s;
}
.icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid var(--color-white);
        position: absolute;
        top: var(--height-header);
        left: -100vw;
        height: calc(100vh - 1px - var(--height-header));
        opacity: 1;
        transition: all 1s ease;
        z-index: 1;
    }

    .mobileMenuActive {
        background: var(--color-primary);
        left: 0;
        transition: all 0.5s ease-in-out;
        z-index: 1;
        top: var(--height-header);
    }

    .links:hover {
        color: var(--color-white);
    }

    .links:before {
        display: none;
    }

    .links:after {
        display: none;
    }
    .icon {
        display: block;
        padding: 10px;
        margin-right: 20px;
        top: 0;
        right: 0;
        font-size: 1.8rem;
        cursor: pointer;
        color: var(--color-secondary);
    }
}