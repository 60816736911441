.container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: calc((100vh - var(--height-footer) - var(--height-header)) / 2);
  flex-direction: column;
}

.loader {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 20;
  background-color: var(--color-dark-trans);
}

.header {
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
}

.h2 {
  font-size: 17px;
  margin: 0px;
  text-align: center;
}

.routesInfo {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-light-grey);
  padding: 8px;
  border-top: solid 2px var(--color-primary);
}

.icon {
  font-size: 25px;
}

.icons {
  display: flex;
  justify-content: space-evenly;
  padding: 5px;
}

.distance {
  color: var(--color-secondary);
  margin: auto 5px;
  font-size: 20px;
  font-weight: 700;
}
.iconCard {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.description {
  height: 100%;
  background-color: var(--color-white-trans);
  margin: 1px 5px;
  padding: 10px;
  margin: 5px 0;
  border-radius: 20px;
  overflow-y: auto;
}

.weatherElevationBtn {
  padding: 0 8px;
}

.downloadContainer {
  display: flex;
  padding: 0 10px;
  justify-content: space-around;
  gap: 5px;
  margin-top: auto;
}

.downloadNote {
  align-self: center;
}

.downloadButton {
  display: none;
}

@media screen and (min-width: 769px) {
  .container {
    max-height: initial;
  }
  .weatherElevationBtn {
    display: none;
  }
  .header {
    justify-content: center;
  }
  .h2 {
    font-size: 24px;
  }
  .description {
    margin: 10px 0;
  }
  .downloadButton {
    display: block;
    font-weight: 700;
  }
  .routesInfo {
    margin: 5px;
    border-radius: 20px;
    border: solid 2px var(--color-primary);
  }
}

@media screen and (min-width: 961px) {
  .icon {
    font-size: 40px;
  }
  .distance {
    font-size: 30px;
  }
  .icons {
    padding: 10px;
  }
}
