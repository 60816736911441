.container {
  width: 100%;
  display: flex;
  gap: 10px;
}

.slider {
  width: 100%;
  align-self: center;
  z-index: 10;
}

.slider_number {
  min-width: 20%;
  z-index: 10;
  margin: 0 2px;
  line-height: 25px;
  align-self: center;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
