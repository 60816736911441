.container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding-top: 3px;
  }
  
  .h2 {
    font-size: 15px;
    margin: 0px;
    text-align: center;
  }
  
  .icon {
    font-size: 25px;
  }
  
  .icons {
    display: flex;
    justify-content: space-evenly;
    padding: 3px;
  }

  .icons svg {
    font-size: 25px;
  }
  
  .distance {
    color: var(--color-secondary);
    margin: auto 5px;
    font-size: 20px;
    font-weight: 700;
  }
  .iconCard {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  