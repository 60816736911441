.publicTransportInfoButton {
  display: flex;
  outline: none;
  font-size: 20px;
  align-self: center;
  border: solid 1px #fff0;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
}
.dialog {
  width: 100%;
  max-width: 600px;
}
.publicTransportInfo {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-left: 0;
}
.publicTransportInfo > li {
  display: flex;
  padding-bottom: 15px;
}
.icon {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-right: 15px;
  padding-bottom: 20px;
}

@media screen and (min-width: 769px) {
  .publicTransportInfo {
    margin: 0 3vw;
  }
}
