.toast {
  display: flex;
  width: calc(100vw - 40px);
  z-index: 5;
}

.container {
  color: var(--color-passive);
}

.toastTitle {
  font-weight: 700;
}

.toastContent {
  font-size: 14px;
  margin: 5px 0;
}

.link {
  color: var(--color-passive);
}

.toastBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
