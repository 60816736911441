.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 33px;
  padding: 3px 0;
  gap: 2px;
}

.h1 {
  align-self: center;
  padding: 0 15px;
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  color: var(--color-primary);
}

.RoutesList {
  height: 100%;
  overflow-y: scroll;
  padding: 0;
  margin: 0;
}

.weatherElevationBtn {
  display: inline;
  font-size: 20px;
  align-items: center;
  align-content: center;
  background-color: var(--color-white-trans);
  border-radius: 10px;
  padding: 0 5px;
  margin: 0 20px;
  border: solid 1px var(--color-dark-trans);
  cursor: pointer;
}

.weatherElevationBtn:hover {
  background-color: var(--color-white);
  box-shadow: 1px 1px 2px var(--color-dark-trans);
}

.noRoutes {
  list-style-type: none;
  margin: 20px;
  padding: 20px;
  text-align: center;
}

.dialog {
  width: 100%;
  max-width: 400px;
}

.sliderRoutelist {
  width: 100%;
  display: none;
  max-width: 350px;
}

.sliderFilter  {
  display: inherit;
}

.dialog_row {
  display: flex;
  align-items: center;
  background-color: var(--color-light-grey);
  padding: 5px 20px;
  margin: 5px 0;
  border-radius: 10px;
  cursor: pointer;
  border: solid 1px var(--color-light-grey);
}
.dialog_row:hover {
  border-color: var(--color-secondary);
}

.label {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 10px;
}
.label svg {
  font-size: 30px;
  height: auto;
  margin: 0 5px;
}

@media screen and (min-width: 769px) {
  .weatherElevationBtn {
    display: none;
  }
}

@media screen and (min-width: 961px) {
  .sliderRoutelist {
    display: inherit;
  }
  .sliderFilter {
    display: none;
  }
}